/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import { Link } from 'gatsby';

// PropTypes
import PropTypes from 'prop-types';

// UI lib components
import {
  Container,
  Row,
  Col,
  Visible,
  useScreenClass,
} from 'react-grid-system';

// UI local components
import BannerSection from '../BannerSection';
import SideMenu from '../MenuSection/SideMenu';
import HealthPillar from '../healthPillar';
import Events from '../Events';
import ActualitiesSectionIndex from '../page-index/actualities-section';
import PCN from '../PCN';
import UsefullLinks from '../usefulLinks';

// Assets
import BANNER_IMG from '../../images/pillar-description/banner.png';

// Style
import './index.scss';
/* -------------------------------------------------------------------------- */
/*                           Thematique Page                                  */
/* -------------------------------------------------------------------------- */

function ClusterPageTemplate({ searchInput, clusterPageData }) {
  /* ------------------------------- CONST --------------------------------- */
  const screenClass = useScreenClass();
  const IS_MOBILE = ['xs', 'sm', 'md'].includes(screenClass);

  /* ****************************** HELPERS ******************************** */

  // Menu
  function GetMenu() {
    return (
      <>
        {clusterPageData.attributes.page_pilier.data.attributes.page_clusters.data
          .sort((elm1, elm2) => elm1.id - elm2.id)
          .map((link) => (
            <li
              className={`${
                link.id === clusterPageData.id ? 'actived_button' : ''
              } single-link text-center flex justify-center items-center fluid`}
            >
              <Link to={`/cluster/${link.id}/#events`}>
                <h5>{link.attributes.title}</h5>
              </Link>
            </li>
          ))}
      </>
    );
  }

  /* ******************************* RENDERING ***************************** */
  return (
    <Container className="thematique-page fluid" fluid>
      <BannerSection
        title={clusterPageData.attributes?.page_pilier?.data.attributes.title}
        subtitle={
          clusterPageData.attributes?.page_pilier?.data.attributes.sub_title
        }
        bannerImage={BANNER_IMG}
        description={
          clusterPageData.attributes.page_pilier.data.attributes.blocks[0]
            .description
        }
      />
      <Container className="menu-section grayish-background">
        <Container className={`${IS_MOBILE ? 'fluid' : ''}`}>
          <ul className="topMenu flex fluid">{GetMenu()}</ul>
        </Container>
      </Container>
      <Container>
        <Visible lg xl xxl>
          <Container className="top flex items-center fluid">
            <Link className="breadcrumb__link" to="/">
              Accueil
            </Link>
            <span className="m-r-l">/</span>
            <Link
              className="breadcrumb__link"
              to={`/pillar/${clusterPageData.attributes?.page_pilier?.data?.attributes?.slug}`}
            >
              {clusterPageData.attributes?.page_pilier?.data?.attributes?.title}
            </Link>
            <span className="m-r-l">/</span>
            <Link
              className="breadcrumb__link"
              to={`/cluster/${clusterPageData.id}`}
            >
              {clusterPageData.attributes.title}
            </Link>
          </Container>
        </Visible>
      </Container>
      <Container>
        <Row className="mt-4 fluid">
          <Col
            className="menu-container fluid"
            xxl={3}
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={12}
          >
            <SideMenu cluserPage={clusterPageData.attributes.slug} />
          </Col>
          <Col className="fluid" xxl={9} xl={9} lg={9} md={9} sm={12} xs={12}>
            <HealthPillar clusterPageData={clusterPageData} />
            <Events />
            <Container className="actualities-section-cluster fluid">
              <ActualitiesSectionIndex />
            </Container>
            <PCN searchInput={searchInput} clusterPageData={clusterPageData} />
            {clusterPageData?.attributes?.useful_links?.data?.length !== 0 && (
              <UsefullLinks clusterPageData={clusterPageData} />
            )}
            {/* <Downloads /> */}
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

ClusterPageTemplate.propTypes = {
  searchInput: PropTypes.string.isRequired,
  clusterPageData: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default ClusterPageTemplate;
