/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';
import { Router as MyRouter } from '@reach/router';

import { navigate } from 'gatsby';
import axios from 'axios';

// ProtoTypes
import PropTypes from 'prop-types';

// UI local components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import ClusterPageTemplate from '../page-components/ClusterPageTemplate';

// Helpers
import capitalizeLetters from '../shared/Helpers/capitalizeLetters';

// API link
import { API_CLUSTERS } from '../shared/APIs';

// Style
import '../page-styles/pillar.scss';

/* -------------------------------------------------------------------------- */
/*                               Cluster Page                                 */
/* -------------------------------------------------------------------------- */

function ClusterPage({ location }) {
  /* ------------------------------- HOOKS ---------------------------------- */
  const [clusterPageData, setClusterPageDta] = useState({});
  const [clusterData, setClusterData] = useState({});
  const [loader, setLoader] = useState(true);
  /* ------------------------------ HELPERS --------------------------------- */
  useEffect(() => {
    setLoader(true);
    if (location.pathname === '/cluster') {
      navigate('/');
    }

    const GET_CLUSTER_ID = parseInt(location.pathname.split('/')[2], 10);

    axios
      .get(API_CLUSTERS(GET_CLUSTER_ID))
      .then(({ data: { data } }) => {
        setClusterPageDta(data);
        setClusterData(data);
      })
      .then(() => setLoader(false))
      .catch((error) => error);
  }, [location]);

  /* ****************************** RENDERING ****************************** */
  return (
    <PageLayout loader={loader} location={location}>
      <Seo title={capitalizeLetters(clusterPageData.attributes?.title)} />
      <MyRouter>
        <ClusterPageTemplate
          clusterData={clusterData}
          clusterPageData={clusterPageData}
          location={location}
          path={`/cluster/${clusterPageData.id}`}
        />
      </MyRouter>
    </PageLayout>
  );
}

ClusterPage.propTypes = {
  location: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default ClusterPage;
